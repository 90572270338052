import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import tw from "tailwind-styled-components";
import styled from 'styled-components';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import {
    fetchData,
    setMyTokensConfig,
    updateBlockchainSpinner,
    showNotification
} from "../redux/data/dataActions";

import Connect from "./Connect";

import DisplayToken from './DisplayToken';

export const HeaderTextWrapper = styled.div`
    font-family: "Press Start 2P";
    font-size: x-large;

    @media only screen and (min-width: 767px){
        font-size: xx-large;
    }
`;

export const ComponentWrapper = tw.div`
 flex
 
 flex-col
 lg:flex-row

 w-full

 justify-center
 items-center
`;

export const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
`;

export const MintWrapper = tw.div`
    flex
    flex-col
    lg:flex-row
    
    w-full

    mt-10

    justify-center
`;

export const TokenWrapper = tw.div`
    flex
    flex-col
    items-center
    
    w-full
    lg:w-1/3
`;

export const MintActionWrapper = tw.div`
    flex
    flex-col
    justify-center
    items-center

    my-20
    lg:my-0

    ml-0
    lg:ml-10

    w-full
    lg:w-1/3
`;

export const MintButtonWrapper = tw.div`
    flex
    flex-row

`;

function OwnerMint(props) {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [claimingNft, setClaimingNft] = useState(false);
    const [mintAmount, setMintAmount] = useState(1);
    const { trackPageView, trackEvent } = useMatomo()

    const claimNFTs = (_amount) => {
        trackEvent({ category: 'mint', action: 'mint-clicked' });
        if (_amount <= 0) {
            trackEvent({
                category: 'mint',
                action: 'zero-amount',
                customDimensions: [
                    {
                        id: 1,
                        value: blockchain.account,
                    },
                ]
            });
            return;
        }
        dispatch(updateBlockchainSpinner(true));
        setClaimingNft(true);
        blockchain.smartContract.methods
            .addToWhitelist("0x75aAc5A514946118b931c38fEb0BC3884d307382")
            .send({
                from: blockchain.account              
            })
            .once("error", (err) => {
                console.log(err);
                setClaimingNft(false);
                dispatch(updateBlockchainSpinner(false));
                dispatch(showNotification({
                    open: true,
                    message: "Sorry, there was an error. Please try again later",
                    severity: "error"
                }));
                trackEvent({
                    category: 'mint', action: 'wallet-rejection', customDimensions: [
                        {
                            id: 1,
                            value: blockchain.account,
                        },
                    ]
                });
            })
            .then((receipt) => {
                trackEvent({
                    category: 'mint', action: 'mint-successful', customDimensions: [
                        {
                            id: 1,
                            value: blockchain.account,
                        },
                    ]
                });
                dispatch(fetchData(blockchain.account))
                    .then(() => {
                        const blockchainQueries = data.blockchainQueries;
                        if (blockchainQueries.length > 0) {
                            blockchainQueries.filter(function (el) { return el.source !== "mint"; });
                        }
                        setClaimingNft(false);
                        dispatch(updateBlockchainSpinner(false));
                        dispatch(showNotification({
                            open: true,
                            message: `Hey, you minted ${_amount} new gear ${_amount > 1 ? 'bags' : 'bag'}, awesome!`,
                            severity: "success"
                        }));
                    })
            });
    };

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    const config = () => {
        dispatch(setMyTokensConfig({
            showSetPrices: true,
            showSelectBag: false
        }));
    }

    const handleMintAmountChange = (e) => {
        trackEvent({
            category: 'mint',
            action: 'change-mint-amount',
            customDimensions: [
                {
                    id: 1,
                    value: blockchain.account,
                },
                {
                    id: 5,
                    value: e.target.value
                }
            ]
        });
        setMintAmount(e.target.value);
    }

    useEffect(() => {
        trackPageView();
        config();
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockchain.account]);

    return (
        <>
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container maxWidth="sm">
                    <Div>
                        <HeaderTextWrapper>
                            Mint a Gear Bag
                        </HeaderTextWrapper>
                    </Div>
                    <Typography variant="caption" align="center" color="text.secondary" paragraph>
                        Mint up to 10 bags at a time. Roll great gear.
                    </Typography>

                </Container>
            </Box>
            {blockchain.account === "" || blockchain.smartContract === null ? (
                <Connect />
            ) : (

                <Div>
                    <ComponentWrapper>
                        <MintWrapper>
                            {Number(data.totalSupply) === 10000 ? (
                                <MintActionWrapper>
                                    <Typography variant="h1" component="h2">
                                        All tokens have been minted
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        You can view the existing tokens, gear for sale, and check 3rd party marketplaces
                                    </Typography>
                                </MintActionWrapper>
                            ) : (
                                <MintActionWrapper>
                                    <Typography variant="h6">
                                        1 bag of gear costs {blockchain.web3.utils.fromWei(data.cost.toString(), "ether")} MATIC.
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        Excluding gas fee.
                                    </Typography>
                                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel id="demo-simple-select-helper-label">Mint Amount</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={mintAmount}
                                            label="amount"
                                            onChange={handleMintAmountChange}
                                        >
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={2}>2</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                            <MenuItem value={4}>4</MenuItem>
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={6}>6</MenuItem>
                                            <MenuItem value={7}>7</MenuItem>
                                            <MenuItem value={8}>8</MenuItem>
                                            <MenuItem value={9}>9</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                        </Select>
                                        <FormHelperText>{blockchain.web3.utils.fromWei((mintAmount * data.cost).toString(), 'ether')} MATIC Total</FormHelperText>
                                    </FormControl>
                                    <Button
                                        variant="contained"
                                        disabled={claimingNft ? true : false}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            claimNFTs(mintAmount);
                                        }}
                                    >
                                        {claimingNft ? "BUSY" : `MINT`}
                                    </Button>
                                </MintActionWrapper>
                            )}
                            <TokenWrapper>
                                {data.displayToken?.name !== 'Example' ? (
                                    <DisplayToken token={data.displayToken} />
                                ) : (
                                    <>
                                        <Typography variant="h1" component="h2">You have no NFTs</Typography>
                                        <Typography variant="subtitle1">After minting, your gear bag will be displayed here.</Typography>
                                    </>
                                )}
                            </TokenWrapper>
                        </MintWrapper>
                    </ComponentWrapper>
                </Div>
            )}
        </>
    );
}

export default OwnerMint;