import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from 'styled-components';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import Connect from "./Connect";
import { useMatomo } from '@datapunt/matomo-tracker-react'

import DisplayPriceList from "./DisplayPriceList";

import {
    updateBlockchainSpinner,
    setTradeSlotId,
    resetTradeBagsAfterTrade
} from '../redux/data/dataActions';

export const Grid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
`;

export const SmallText = styled.div`
    margin: 10px;
    font-size: x-small;
`;

export const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
`;

export const HeaderTextWrapper = styled.div`
    font-family: "Press Start 2P";
    font-size: x-large;

    @media only screen and (min-width: 767px){
        font-size: xx-large;
    }
`;


function ViewPrices(props) {
    const { trackPageView } = useMatomo();
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);

    useEffect(() => {
        dispatch(resetTradeBagsAfterTrade());
        function getData() {
            if (blockchain.account !== "" && blockchain.smartContract !== null){
                trackPageView();
                dispatch(updateBlockchainSpinner(true));
                dispatch(setTradeSlotId(data.viewSlotId, blockchain.account));
            }
        }
        getData();
    }, [blockchain.account, blockchain.smartContract, dispatch, data.viewSlotId, trackPageView])

    return (
        <>
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container maxWidth="sm">
                    <Div>
                        <HeaderTextWrapper>
                            Gear for Sale
                        </HeaderTextWrapper>
                    </Div>
                    <Typography variant="caption" align="center" color="text.secondary" paragraph>
                        Find the perfect piece. Click on an item to start trading
                    </Typography>

                </Container>
            </Box>
            {blockchain.account === "" || blockchain.smartContract === null ? (
                <Connect />
            ) : (
                <>
                    <DisplayPriceList />
                </>
            )}
        </>
    )
}

export default ViewPrices;