import * as React from 'react';
import { useSelector } from "react-redux";
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


export default function TradeFormSlot() {
  const data = useSelector((state) => state.data);
  const tradeState = useSelector((state) => state.data.tradeState);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        You want this
      </Typography>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {data.viewSlotName}
          </Typography>
          <Typography variant="h5" component="div">
            {tradeState.targetItemName}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {tradeState.targetItemCostEth} MATIC
          </Typography>
        </CardContent>
      </Card>
    </React.Fragment >
  );
}