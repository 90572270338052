import React from "react";
import { useSelector } from "react-redux";

import styled from 'styled-components';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const SpinnerWrapper = styled.div`
    position:absolute;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    z-index: 99;
    width: 100%;
    height: 100%;
`;

export const BackgroundHaze = styled.div`
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.5;
`;

function LongRunningRequestSpinner() {
    // let blockchainQuery = {
    //     source: '',
    //     showSpinner: false,
    //     displayMessage: ''
    //   };
    //const showSpinner = (blockchainQueries.length > 0 && blockchainQueries.filter(e => e.showSpinner === true).length > 0);

    const showSpinner = useSelector((state) => state.data.showBlockchainQuerySpinner);

    return (
        <>
            {showSpinner ? (
                <SpinnerWrapper>
                    <BackgroundHaze>
                        <Box sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                        }}>
                            <CircularProgress
                                size={150}
                            />
                        </Box>
                    </BackgroundHaze>
                </SpinnerWrapper>
            ) : (
                <>
                </>
            )}

        </>
    )
}

export default LongRunningRequestSpinner;