// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import SmartContract from "../../contracts/LootSlot.json";
// log
import { 
  fetchData,
  showNotification
 } from "../data/dataActions";

 import config from "../../config.json"

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const { ethereum } = window;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (metamaskIsInstalled) {
      Web3EthContract.setProvider(ethereum);
      let web3 = new Web3(ethereum);
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        const networkId = await ethereum.request({
          method: "net_version",
        });
        // const NetworkData = await SmartContract.networks[networkId]; 

        // using == intentially. sometimes it's an int, sometimes string. fucks up mobile
        // eslint-disable-next-line
        if (networkId == config.networkId) {
          const SmartContractObj = new Web3EthContract(
            SmartContract,
            config.contractAddress
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: SmartContractObj,
              web3: web3,
            })
          );
          // Add listeners start
          ethereum.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
          });
          ethereum.on("chainChanged", () => {
            window.location.reload();
          });
          // Add listeners end
        } else {
          dispatch(showNotification({
            open: true,
            message: `Change network to Polygon. [n:${networkId},cn:${config.networkId}]`,
            severity: "error"
          }));
        }
      } catch (err) {
        dispatch(showNotification({
          open: true,
          message: "Something went wrong.",
          severity: "error"
        }));
      }
    } else {
      dispatch(showNotification({
        open: true,
        message: "Install MetaMask.",
        severity: "error"
      }));
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};
