import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom'
import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { CardHeader } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import SvgIcon from '@mui/material/SvgIcon';
import config from '../config.json';

import {
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    WeiboShareButton,
    FacebookIcon,
    TelegramIcon,
    TwitterIcon,
    WeiboIcon,
    WhatsappIcon
} from "react-share";

import Connect from "./Connect";
import {
    fetchSingleDisplayToken,
    showNotification
} from "../redux/data/dataActions";

export const Img = styled.img`
    width: 450px;
    margin: 15px;
`;

export const MarginBottom = styled.div`
    margin-bottom: 25px;
`;

export const ShareDiv = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const ShareButtonWrapper = styled.div`    margin-right: 15px;
    margin: 5px 5px;
`;

export const TokenWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    @media only screen and (min-width: 767px){
        min-width: 500px;
        width: 25%;
        margin: 50px;
    }
`;

export const SelectForTradeWrapper = tw.div`
    flex
    flex-row
    justify-center
    items-center
    w-full
`;

export const HeaderTextWrapper = styled.div`
    font-family: "Press Start 2P";
    font-size: x-large;

    @media only screen and (min-width: 767px){
        font-size: xx-large;
    }
`;

export const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
`;

export const CopyToClipboardContainer = styled.div`
    width: 128;
    height: 128;
`;

export const MainContainer = tw.div`
    flex
    w-full
    md:w-4/5
    lg:w-2/3
    xl:w-1/3
`;

function DisplayToken(props) {
    const dispatch = useDispatch();
    const { tokenId } = useParams();
    const blockchain = useSelector((state) => state.blockchain);
    const token = useSelector((state) => state.data.displayToken);
    const [showToken, setShowToken] = useState(false);
    const [shareOpen, setShareOpen] = useState(false);
    const [shareAnchorEl, setShareAnchorEl] = useState(null);
    const [sharePopoverId, setSharePopoverId] = useState("popover_1");
    const shareUrl = `${window.location.origin}/viewbag/${tokenId}`;
    const openSeaUrl = config.networkId !== "137" ? `https://testnets.opensea.io/assets/${config.contractAddress}/${tokenId}` : `https://opensea.io/assets/matic/${config.contractAddress}/${tokenId}`;
    const { trackPageView, trackEvent } = useMatomo()

    const handleShareClick = (e) => {
        const tokenId = e.target.attributes["data-tokenid"].value;
        setSharePopoverId(`popover_token_${tokenId}`);
        setShareAnchorEl(e.currentTarget.parentNode);
        setShareOpen(true);
    }

    const handleCopyClick = () => {
        dispatch(showNotification({
            open: true,
            message: `Copied URL to clipboard!`,
            severity: "success"
        }));
    }

    const handleShareClose = () => {
        setShareOpen(false);
    }

    useEffect(() => {
        async function getData() {
            if (blockchain.account !== "" && blockchain.smartContract !== null) {
                dispatch(showNotification({
                    open: true,
                    message: `Fetching details for Bag #${tokenId}.`,
                    severity: "info"
                }));

                dispatch(fetchSingleDisplayToken(tokenId));

                dispatch(showNotification({
                    open: true,
                    message: `Successfully fetched Bag #${tokenId}.`,
                    severity: "success"
                }));

                setShowToken(true);
            }
        };
        trackPageView();
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockchain.account, trackPageView]);

    return (
        <div>
            {blockchain.account === "" || blockchain.smartContract === null ? (
                <Connect />
            ) : (
                <div>
                    {showToken ? (
                        <>
                            <Box
                                sx={{
                                    bgcolor: 'background.paper',
                                    pt: 8,
                                    pb: 6,
                                }}
                            >
                                <Container maxWidth="sm">
                                    <Div>
                                        <HeaderTextWrapper>
                                            {`Gear Bag ${tokenId}`}
                                        </HeaderTextWrapper>
                                    </Div>
                                    <Typography variant="caption" align="center" color="text.secondary" paragraph>
                                        View a single bag, check out the gear.
                                    </Typography>

                                </Container>
                            </Box>
                            <Popover
                                id={sharePopoverId}
                                open={shareOpen}
                                anchorEl={shareAnchorEl}
                                onClose={handleShareClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                sx={{ height: '100px' }}
                            >
                                <ShareDiv>
                                    <ShareButtonWrapper>
                                        <TwitterShareButton
                                            url={shareUrl}
                                        >
                                            <TwitterIcon size={32} />
                                        </TwitterShareButton>
                                    </ShareButtonWrapper>

                                    <ShareButtonWrapper>
                                        <TelegramShareButton
                                            url={shareUrl}
                                        >
                                            <TelegramIcon size={32} />
                                        </TelegramShareButton>
                                    </ShareButtonWrapper>

                                    <ShareButtonWrapper>
                                        <FacebookShareButton
                                            url={shareUrl}
                                        >
                                            <FacebookIcon size={32} />
                                        </FacebookShareButton>
                                    </ShareButtonWrapper>

                                    <ShareButtonWrapper>
                                        <WhatsappShareButton
                                            url={shareUrl}
                                        >
                                            <WhatsappIcon size={32} />
                                        </WhatsappShareButton>
                                    </ShareButtonWrapper>

                                    <ShareButtonWrapper>
                                        <WeiboShareButton
                                            url={shareUrl}
                                        >
                                            <WeiboIcon size={32} />
                                        </WeiboShareButton>
                                    </ShareButtonWrapper>
                                    <ShareButtonWrapper>
                                        <CopyToClipboardContainer                            >
                                            <CopyToClipboard text={shareUrl}
                                                onCopy={handleCopyClick}>
                                                <SvgIcon
                                                    color="primary"
                                                    fontSize="large"
                                                    component={AssignmentOutlinedIcon}
                                                />
                                            </CopyToClipboard>
                                        </CopyToClipboardContainer>
                                    </ShareButtonWrapper>
                                </ShareDiv>
                            </Popover>
                            <Container sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: {
                                    xs: '100%',
                                    sm: '80%',
                                    md: '50%',
                                    lg: '40%',
                                    xl: '30%'
                                }
                            }}>
                                <Card sx={{ width: '100%' }} id={('token_' + tokenId)}>
                                    <CardHeader
                                        title={token.name} />
                                    <CardMedia
                                        component="img"
                                        alt={token.name}
                                        height="250"
                                        image={token.image}
                                    />
                                    <CardActions>
                                        <Button data-tokenid={tokenId} size="small" onClick={handleShareClick}>Share</Button>
                                        <div onClick={() => {
                                            trackEvent({ category: 'view-single-token', action: 'view-on-opensea' });
                                        }}>
                                            <a href={openSeaUrl} target="_blank" rel="noreferrer">
                                                <Button size="small">OpenSea</Button>
                                            </a>
                                        </div>
                                    </CardActions>
                                </Card>
                            </Container>
                        </>
                    ) : (
                        <Container>
                            <Box>
                                <Typography>
                                    Fetching token data...
                                </Typography>
                            </Box>
                        </Container>
                    )}
                </div>
            )}
        </div>
    )
}

export default DisplayToken;