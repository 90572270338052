import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import tw from "tailwind-styled-components";
import styled from 'styled-components';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useMatomo } from '@datapunt/matomo-tracker-react'

import {
    fetchData,
    setMyTokensConfig,
    updateBlockchainSpinner,
    showNotification
} from "../redux/data/dataActions";

import Connect from "./Connect";

export const HeaderTextWrapper = styled.div`
    font-family: "Press Start 2P";
    font-size: x-large;

    @media only screen and (min-width: 767px){
        font-size: xx-large;
    }
`;

export const ComponentWrapper = tw.div`
 flex
 
 flex-col
 lg:flex-row

 w-full

 justify-center
 items-center
`;

export const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
`;

export const MintWrapper = tw.div`
    flex
    flex-col
    lg:flex-row
    
    w-full

    mt-10

    justify-center
`;

export const TokenWrapper = tw.div`
    flex
    flex-col
    items-center
    
    w-full
    lg:w-1/3
`;

export const MintActionWrapper = tw.div`
    flex
    flex-col
    justify-center
    items-center

    my-20
    lg:my-0

    ml-0
    lg:ml-10

    w-full
    lg:w-1/3
`;

export const MintButtonWrapper = tw.div`
    flex
    flex-row

`;

function OwnerWhitelist(props) {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [claimingNft, setClaimingNft] = useState(false);
    const { trackPageView, trackEvent } = useMatomo()
    const [addressToWhitelist, setAddressToWhitelist] = useState("");

    const whitelistAddress = () => {
        trackEvent({ category: 'owner-whitelist', action: 'add-to-whitelist-clicked' });
        dispatch(updateBlockchainSpinner(true));
        setClaimingNft(true);
        debugger;
        blockchain.smartContract.methods
            .addToWhitelist(addressToWhitelist)
            .send({
                from: blockchain.account
            })
            .once("error", (err) => {
                console.log(err);
                setClaimingNft(false);
                dispatch(updateBlockchainSpinner(false));
                dispatch(showNotification({
                    open: true,
                    message: "Sorry, there was an error. Please try again later",
                    severity: "error"
                }));
                trackEvent({
                    category: 'owner-whitelist', action: 'wallet-rejection', customDimensions: [
                        {
                            id: 1,
                            value: blockchain.account,
                        },
                    ]
                });
            })
            .then((receipt) => {
                trackEvent({
                    category: 'owner-whitelist', action: 'whitelist-successful', customDimensions: [
                        {
                            id: 1,
                            value: blockchain.account,
                        },
                    ]
                });
                dispatch(fetchData(blockchain.account))
                    .then(() => {
                        const blockchainQueries = data.blockchainQueries;
                        if (blockchainQueries.length > 0) {
                            blockchainQueries.filter(function (el) { return el.source !== "mint"; });
                        }
                        setClaimingNft(false);
                        dispatch(updateBlockchainSpinner(false));
                        dispatch(showNotification({
                            open: true,
                            message: `Hey, you added ${addressToWhitelist} to the whitelist, awesome!`,
                            severity: "success"
                        }));
                    })
            });
    };

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    const config = () => {
        dispatch(setMyTokensConfig({
            showSetPrices: true,
            showSelectBag: false
        }));
    }

    useEffect(() => {
        trackPageView();
        config();
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockchain.account]);

    const handleOnChange = (e) => {
        setAddressToWhitelist(e.target.value);
    }

    return (
        <>
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container maxWidth="sm">
                    <Div>
                        <HeaderTextWrapper>
                            Mint a Gear Bag
                        </HeaderTextWrapper>
                    </Div>
                    <Typography variant="caption" align="center" color="text.secondary" paragraph>
                        Mint up to 10 bags at a time. Roll great gear.
                    </Typography>

                </Container>
            </Box>
            {blockchain.account === "" || blockchain.smartContract === null ? (
                <Connect />
            ) : (

                <Div>
                    <ComponentWrapper>
                        <MintWrapper>
                            {Number(data.totalSupply) === 10000 ? (
                                <MintActionWrapper>
                                    <Typography variant="h1" component="h2">
                                        All tokens have been minted
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        You can view the existing tokens, gear for sale, and check 3rd party marketplaces
                                    </Typography>
                                </MintActionWrapper>
                            ) : (
                                <MintActionWrapper>
                                    <Typography variant="h6">
                                        Add a user to the whitelist
                                    </Typography>
                                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel id="demo-simple-select-helper-label">Whitelist Address</InputLabel>
                                        <Input placeholder="Whitelist Address" value={addressToWhitelist} onChange={handleOnChange} />                                        
                                    </FormControl>
                                    <Button
                                        variant="contained"
                                        disabled={claimingNft ? true : false}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            whitelistAddress();
                                        }}
                                    >
                                        {claimingNft ? "BUSY" : `MINT`}
                                    </Button>
                                </MintActionWrapper>
                            )}
                        </MintWrapper>
                    </ComponentWrapper>
                </Div>
            )}
        </>
    );
}

export default OwnerWhitelist;