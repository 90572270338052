import React from "react";
import styled from 'styled-components';
import Paper from '@mui/material/Paper';


import Container from '@mui/material/Container';

export const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    
`;

export const HeaderTextWrapper = styled.div`
    diplay: flex;
    justify-content: end;
    align-items: end;
    flex-wrap: wrap;
    font-family: "Press Start 2P";
    font-size: 1.2em;
    margin-top: 30px;
    margin-bottom: 30px;
    @media only screen and (min-width: 767px){
        font-size: large;
    }
`;

function Dropping(props) {
    return (
        <Container maxWidth="md">
            <Div>
                <HeaderTextWrapper>
                    <Paper elevation={3}
                        sx={{
                            padding: 2,
                        }}
                    >
                        Dropping in October
                    </Paper>
                </HeaderTextWrapper>
            </Div>
        </Container>
    );
}

export default Dropping