import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import {
    setTargetTradeData,
    setActiveTradeStep,
    initTradeState,
    showNotification,
    updateBlockchainSpinner,
    fetchOwnerTokenIds,
    setTradeSlotId,
    fetchSingleToken,
    resetTradeBagsAfterTrade
} from "../redux/data/dataActions";

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useMatomo } from '@datapunt/matomo-tracker-react'

import TradeFormSlot from './TradeFormSlot';
import TradeFormBag from './TradeFormBag';
import TradeFormReview from './TradeFormReview';
import OldBagNewBagDisplay from './OldBagNewBagDisplay';
import tw from "tailwind-styled-components"

import Connect from "./Connect";

const steps = ['Theirs', 'Yours', 'Review'];

function getStepContent(step) {
    switch (step) {
        case 0:
            return <TradeFormSlot />;
        case 1:
            return <TradeFormBag />;
        case 2:
            return <TradeFormReview />;
        default:
            throw new Error('Unknown step');
    }
}

export const Div = tw.div`
    flex
    flex-col
    justify-center
    items-center
    w-full
`;

export default function TradeFlow() {
    const dispatch = useDispatch();
    const history = useHistory();
    const tradeState = useSelector((state) => state.data.tradeState);
    const activeStep = useSelector((state) => state.data.tradeState.activeStep);
    const data = useSelector((state) => state.data);
    const blockchain = useSelector((state) => state.blockchain);
    const [displayThankYouMessage, setDisplayThankYouMessage] = useState(false);
    const { trackPageView, trackEvent } = useMatomo()

    useEffect(() => {
        trackPageView();
        if (tradeState.targetItemCostWei === 0 || tradeState.targetItemName === '') {
            dispatch(resetTradeBagsAfterTrade())
            history.push("/viewprices");
        }
        // eslint-disable-next-line
    }, [tradeState, history, trackPageView]);

    const handleNext = () => {
        trackEvent({ category: 'trade-flow', action: 'handle-next' });
        if (activeStep === steps.length - 1) {
            submitTrade().then(
                () => {
                    // success
                    dispatch(setActiveTradeStep(activeStep + 1));
                },
                (err) => {
                    // error
                    console.log(err);
                }
            )
        } else {
            dispatch(setActiveTradeStep(activeStep + 1));
        }
    };

    const handleBack = () => {
        trackEvent({ category: 'trade-flow', action: 'handle-back' });
        dispatch(setActiveTradeStep(activeStep - 1));
    };

    const handleStartNew = () => {
        trackEvent({ category: 'trade-flow', action: 'start-new' });
        dispatch(initTradeState());
        history.push("/viewprices");

        const rowData = {
            itemName: '',
            price: 0,
            bagNumber: 0
        };

        dispatch(setTargetTradeData(rowData));
        dispatch(setActiveTradeStep(0));
    }

    const submitTrade = () => {
        trackEvent({ category: 'trade-flow', action: 'save-prices-clicked' });
        return new Promise((resolve, reject) => {
            if (activeStep !== steps.length - 1) {
                return resolve();
            }
            dispatch(showNotification({
                open: true,
                message: "Sending your trade to the blockchain",
                severity: "info"
            }));
            dispatch(updateBlockchainSpinner(true));

            const tradeObj = {
                sourceTokenId: tradeState.selectedSourceBag,
                targetTokenId: tradeState.selectedTargetBag,
                slotId: data.viewSlotId,
                price: tradeState.targetItemCostEth,
                from: blockchain.account
            }
            blockchain.smartContract.methods.tradeSlotPiece(
                parseInt(tradeObj.targetTokenId),
                parseInt(tradeObj.sourceTokenId),
                tradeObj.slotId
            )
                .send({
                    from: blockchain.account,
                    value: blockchain.web3.utils.toWei(tradeObj.price.toString(), 'ether')
                })
                .once("error", (err) => {
                    trackEvent({ category: 'trade-flow', action: 'submit-trade-error' });
                    setDisplayThankYouMessage(false);
                    dispatch(showNotification({
                        open: true,
                        message: "Sorry, there was an error. Please try again later",
                        severity: "error"
                    }));
                    dispatch(updateBlockchainSpinner(false));
                    console.log(err);
                    return reject();
                })
                .then(() => {
                    trackEvent({ category: 'trade-flow', action: 'submit-trade-success' });
                    dispatch(fetchSingleToken(tradeState.selectedSourceBag))
                    setDisplayThankYouMessage(true);
                    dispatch(showNotification({
                        open: true,
                        message: "Trade completed!",
                        severity: "success"
                    }));
                    dispatch(updateBlockchainSpinner(false));

                    // get my bags for good measure
                    dispatch(fetchOwnerTokenIds(blockchain.account, true, false));

                    // and update the prices for the slot used on this trade
                    dispatch(setTradeSlotId(data.viewSlotId, blockchain.account));
                    return resolve();
                });
        });
    };

    return (
        <>
            {blockchain.account === "" || blockchain.smartContract === null ? (
                <Connect />
            ) : (
                <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                        <Typography component="h1" variant="h4" align="center">
                            Trade Gear Slots
                        </Typography>
                        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <React.Fragment>
                            {activeStep === steps.length ? (
                                <React.Fragment>
                                    {displayThankYouMessage ? (
                                        <Div>

                                            <Typography variant="h5" gutterBottom>
                                                Your trade has completed
                                            </Typography>
                                            <Typography variant="subtitle1">
                                                Journey on, venturer!
                                            </Typography>
                                            {data.selectedTokenData.weapon !== '' ? (
                                                <OldBagNewBagDisplay />
                                            ) : (
                                                <>
                                                    waiting
                                                </>
                                            )}
                                            <Button
                                                variant="contained"
                                                onClick={handleStartNew}
                                                sx={{ mt: 3, ml: 1 }}
                                            >
                                                Find More Great Gear
                                            </Button>
                                        </Div>
                                    ) : (
                                        <>
                                            <Typography variant="h5" gutterBottom>
                                                Trade is in progress
                                            </Typography>
                                            <Typography variant="subtitle1">
                                                Please complete in MetaMask
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                onClick={handleStartNew}
                                                sx={{ mt: 3, ml: 1 }}
                                            >
                                                Start over
                                            </Button>
                                        </>
                                    )}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {getStepContent(activeStep)}
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        {activeStep !== 0 && (
                                            <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                                                Back
                                            </Button>
                                        )}

                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{ mt: 3, ml: 1 }}
                                        >
                                            {activeStep === steps.length - 1 ? 'Execute Trade' : 'Next'}
                                        </Button>
                                    </Box>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </Paper>
                </Container>
            )}
        </>
    );
}