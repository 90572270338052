import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatomo } from '@datapunt/matomo-tracker-react'

import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import tw from 'tailwind-styled-components';
import Dropping from "./Dropping"

export const ComponentWrapper = tw.div`
    flex
    flex-col
    items-center	
    w-full
    my-20
`;

export const ButtonWrapper = tw.div`
    w-1/2
    lg:w-1/4
`;

function Connect(props) {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const isLive = useSelector((state) => state.data.isLive);
    const { trackEvent } = useMatomo()

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    return (
        <>
            {isLive ? (
                <ComponentWrapper>
                    <Divider />
                    <Typography>
                        Connect to the Polygon Mainnet
                        to interact with the app
                    </Typography>
                    <ButtonWrapper>
                        <Button
                            sx={{ width: '100%' }}
                            variant="contained"
                            color="success"
                            endIcon={<SettingsEthernetIcon />}
                            onClick={(e) => {
                                trackEvent({ 
                                    category: 'connect', 
                                    action: 'connect-clicked' 
                                });
                                e.preventDefault();
                                dispatch(connect()).then(
                                    () => {
                                        trackEvent({ 
                                            category: 'connect', 
                                            action: 'connect-success',
                                            customDimensions: [
                                                {
                                                  id: 1,
                                                  value: blockchain.account,
                                                },
                                              ]
                                        });
                                        getData();
                                    },
                                    (err) => {
                                        console.log(err);
                                        trackEvent({ 
                                            category: 'connect', 
                                            action: 'connect-failure' 
                                        });
                                    }
                                );
                            }}>
                            CONNECT
                        </Button>
                    </ButtonWrapper>

                    {blockchain.errorMsg !== "" ? (
                        <Typography>
                            {blockchain.errorMsg}
                        </Typography>
                    ) : null}
                    <Divider />
                </ComponentWrapper>
            ) : (
                <Dropping />
            )}
        </>
    );
}

export default Connect;