import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import {
    closeNotification
} from "../redux/data/dataActions";

function Notification(props) {
    const notifyState = useSelector((state) => state.data.notifyState);
    const dispatch = useDispatch();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(closeNotification());
    };

    const vertical = 'bottom';
    const horizontal = 'right';

    return (
        <>
            <Snackbar
                open={notifyState.open}
                autoHideDuration={6000}
                onClose={handleClose}
                key={vertical + horizontal}
                anchorOrigin={{ vertical, horizontal }}
            >
                <Alert
                    severity={notifyState.severity}
                    onClose={handleClose}
                    sx={{ width: '100%' }}
                >
                    {notifyState.message}
                </Alert>
            </Snackbar>
        </>
    );
}

export default Notification;