import * as React from 'react';
import { useSelector } from "react-redux";
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

import MyTokens from './MyTokens';


const Italic = styled.span`
  font-style: italic;
  font-weight: 600;
`;

export default function TradeFormBag() {
  const data = useSelector((state) => state.data);
  const tradeState = useSelector((state) => state.data.tradeState);

  const formatSlotName = (slotName) => {
    switch(slotName){
      case "Weapons":
        return "weapon";
      case "Shields":
        return "shield";
      case "Helms":
        return "helm";
      case "Chests":
        return "chest armor";
      case "Belts":
        return "belt";
      case "Rings":
        return "ring";
      case "Necks":
        return "neck piece";
      default:
        return slotName;
    }
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Select your bag to trade {data.viewSlotName.toLowerCase()}
      </Typography>
      <Typography variant="caption" gutterBottom>
        Choose your bag holding the {formatSlotName(data.viewSlotName)} you 
        want to swap with <Italic>{tradeState.targetItemName}</Italic>.
      </Typography>      
      <MyTokens />
    </React.Fragment>
  );
}