import * as React from 'react';
import { useSelector } from "react-redux";
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import styled from 'styled-components';

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 100%;
  @media only screen and (min-width: 767px){
    width: 100%;
  }
`;



export default function TradeFormReview() {

  const data = useSelector((state) => state.data);
  const tradeState = useSelector((state) => state.data.tradeState);

  const getSlotName = (otherSlotName) => {
    switch (otherSlotName) {
      case "Boots":
        return "boots";
      case "Gloves":
        return "gloves";
      case "Chest Armor":
        return "chest";
      default:
        return otherSlotName.toLowerCase().slice(0, -1);
    }
  }

  return (
    <React.Fragment>
      <ItemWrapper>
        <Typography variant="h6" gutterBottom>
          You trade your {tradeState.targetItemCostEth} MATIC, and
        </Typography>
        <Card sx={{
          minWidth: 275,
          maxWidth: 500
        }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {data.viewSlotName}
            </Typography>
            <Typography variant="h5" component="div">
              {tradeState.sourceBag[getSlotName(data.viewSlotName)]}
            </Typography>
          </CardContent>
        </Card>
      </ItemWrapper>
      <ItemWrapper>
        <Typography variant="h6" gutterBottom>
          You get
        </Typography>
        <Card sx={{
          minWidth: 275,
          maxWidth: 500
        }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {data.viewSlotName}
            </Typography>
            <Typography variant="h5" component="div">
              {tradeState.targetItemName}
            </Typography>
          </CardContent>
        </Card>
      </ItemWrapper>
    </React.Fragment>
  );
}