
import React from "react";
import Typography from '@mui/material/Typography';

import { useSelector } from "react-redux";
import DisplayTokenAfterTrade from './DisplayTokenAfterTrade';
import tw from "tailwind-styled-components"

export const TokenDisplay = tw.div`
    flex
    flex-col
    justify-center
    items-center

    md:flex-row
`;

export const TokenContainer = tw.div`
    flex
    flex-col
    m-5
    justify-center
    items-center
    border-solid
    rounded-md
    border-black
    border-2
    border-opacity-25
    bg-gray-100
`;

export const HeaderText = tw.div`
    flex
    flex-col
    justify-center
    items-center
`;

function OldBagNewBagDisplay(props) {
    const tradeState = useSelector((state) => state.data.tradeState);
    const data = useSelector((state) => state.data);

    const getSlotName = (otherSlotName) => {
        switch (otherSlotName) {
            case "Boots":
                return "boots";
            case "Gloves":
                return "gloves";
            case "Chest Armor":
                return "chest";
            default:
                return otherSlotName.toLowerCase().slice(0, -1);
        }
    }

    return (
        <TokenDisplay>
            <TokenContainer>
                <HeaderText>
                    <Typography component="h3" variant="h4" align="center">
                        Before Trade
                    </Typography>
                    <Typography variant="h5" component="div">
                        {tradeState.sourceBag[getSlotName(data.viewSlotName)]}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {data.viewSlotName}
                    </Typography>
                </HeaderText>
                <DisplayTokenAfterTrade token={tradeState.sourceBag} showSelectButton={false} activeSlot={data.viewSlotId} />
            </TokenContainer>
            <TokenContainer>
                <HeaderText>
                    <Typography component="h3" variant="h4" align="center">
                        Current
                    </Typography>
                    <Typography variant="h5" component="div">
                        {tradeState.targetItemName}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {data.viewSlotName}
                    </Typography>
                </HeaderText>
                <DisplayTokenAfterTrade token={data.selectedTokenData} showSelectButton={false} activeSlot={data.viewSlotId} />
            </TokenContainer>
        </TokenDisplay>
    )
}

export default OldBagNewBagDisplay;