import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { useMatomo } from '@datapunt/matomo-tracker-react'

import AppStore from '../assets/images/download-on-the-app-store.svg';
import GooglePlay from '../assets/images/google-play-badge.png';

function MobileNotifyModal() {
    const { trackPageView, trackEvent } = useMatomo();
    const [mobileModalDismissed, setMobileModalDismissed] = useState(false);

    const handleClick = () => {
        trackEvent({ category: 'mobile-notify-modal', action: 'handle-click' });
        setMobileModalDismissed(true);
        alert('Copied URL to clipboard');
    }

    const handleClose = () => {
        trackEvent({ category: 'mobile-notify-modal', action: 'handle-close' });
        setMobileModalDismissed(true);
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column'
    };

    const buttonRow = {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    };

    const appButtonStyle = {
        marginTop: '2px',
        width: '45%'
    };

    useEffect(() => {
        trackPageView();
    }, [trackPageView]);

    return (
        <>
            {mobileModalDismissed ? (
                <>
                </>
            ) : (
                <Modal
                    open={!mobileModalDismissed}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Best viewed in MetaMask Browser
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            To interact with Gear Slots on a mobile device
                            please open this URL using the browser bundled with
                            the MetaMask app.
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Tap the button below to copy the URL to your clipboard
                            for easy pasting within MetaMask.
                        </Typography>
                        <CopyToClipboard sx={{ mt: 2 }} text={window.location.origin}
                            onCopy={handleClick}>
                            <Button
                                variant="contained"
                            >Copy URL to Clipboard</Button>
                        </CopyToClipboard>
                        <div style={buttonRow}>
                            <div style={appButtonStyle} onClick={() => {
                                trackEvent({ category: 'mobile-notify-modal', action: 'click-app-store' });
                            }}>
                                <a
                                    href="https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={AppStore}
                                        alt="Download on the App Store" />
                                </a>
                            </div>
                            <div style={appButtonStyle} onClick={() => {
                                trackEvent({ category: 'mobile-notify-modal', action: 'click-google-play' });
                            }}>
                                <a
                                    href="https://play.google.com/store/apps/details?id=io.metamask"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={GooglePlay}
                                        alt="Get it on Google Play" />
                                </a>
                            </div>
                        </div>
                    </Box>
                </Modal>

            )}
        </>
    )
}

export default MobileNotifyModal;