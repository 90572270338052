import styled from 'styled-components';
import tw from "tailwind-styled-components";
import DisplayToken from './DisplayToken';

export const Div = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const TokenWrapper = tw.div`
    flex
    flex-wrap
    flex-row
    justify-evenly
`;

export const TokenContainer = tw.div`
    flex
    flex-wrap
    flex-row
    justify-evenly
    w-full
`;

function DisplayTokensList(props) {
    const tokensDisplay = [];

    for (const token of props?.tokens) {
        tokensDisplay.push(<TokenContainer><DisplayToken token={token.token} key={token.key} /></TokenContainer>);
    }

    return (
        <Div>
            <TokenWrapper>
                {tokensDisplay}
            </TokenWrapper>
        </Div>
    );
}

export default DisplayTokensList;