import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import tw from "tailwind-styled-components";
import styled from 'styled-components';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import {
    fetchData,
    setMyTokensConfig,
    updateBlockchainSpinner,
    showNotification,
    checkIfWhitelisted
} from "../redux/data/dataActions";

import Connect from "./Connect";

import DisplayToken from './DisplayToken';

export const HeaderTextWrapper = styled.div`
    font-family: "Press Start 2P";
    font-size: x-large;

    @media only screen and (min-width: 767px){
        font-size: xx-large;
    }
`;

export const ComponentWrapper = tw.div`
 flex
 
 flex-col
 lg:flex-row

 w-full

 justify-center
 items-center
`;

export const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
`;

export const MintWrapper = tw.div`
    flex
    flex-col
    lg:flex-row
    
    w-full

    mt-10

    justify-center
`;

export const TokenWrapper = tw.div`
    flex
    flex-col
    items-center
    
    w-full
    lg:w-1/3
`;

export const MintActionWrapper = tw.div`
    flex
    flex-col
    justify-center
    items-center

    my-20
    lg:my-0

    ml-0
    lg:ml-10

    w-full
    lg:w-1/3
`;

export const MintButtonWrapper = tw.div`
    flex
    flex-row

`;

function WhitelistMint(props) {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [claimingNft, setClaimingNft] = useState(false);
    const { trackPageView, trackEvent } = useMatomo()
    const isWhitelisted = useSelector((state) => state.data.isWhitelisted);

    const claimNFTs = (_amount) => {
        trackEvent({ category: 'whitelist-mint', action: 'mint-clicked' });
        dispatch(updateBlockchainSpinner(true));

        setClaimingNft(true);

        blockchain.smartContract.methods
            .mint(blockchain.account, 1)
            .send({
                to: blockchain.account,
                from: blockchain.account
            })
            .once("error", (err) => {
                console.log(err);
                setClaimingNft(false);
                dispatch(updateBlockchainSpinner(false));
                dispatch(showNotification({
                    open: true,
                    message: "Sorry, there was an error. Please try again later",
                    severity: "error"
                }));
                trackEvent({
                    category: 'whitelist-mint', action: 'wallet-rejection', customDimensions: [
                        {
                            id: 1,
                            value: blockchain.account,
                        },
                    ]
                });
            })
            .then((receipt) => {
                trackEvent({
                    category: 'whitelist-mint', action: 'mint-successful', customDimensions: [
                        {
                            id: 1,
                            value: blockchain.account,
                        },
                    ]
                });
                dispatch(fetchData(blockchain.account))
                    .then(() => {
                        const blockchainQueries = data.blockchainQueries;
                        if (blockchainQueries.length > 0) {
                            blockchainQueries.filter(function (el) { return el.source !== "mint"; });
                        }
                        setClaimingNft(false);
                        dispatch(updateBlockchainSpinner(false));
                        dispatch(showNotification({
                            open: true,
                            message: `Hey, you minted ${_amount} new gear ${_amount > 1 ? 'bags' : 'bag'}, awesome!`,
                            severity: "success"
                        }));
                    })
            });
    };

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(checkIfWhitelisted(blockchain.account));
            dispatch(fetchData(blockchain.account));
        }
    };

    const config = () => {
        dispatch(setMyTokensConfig({
            showSetPrices: true,
            showSelectBag: false
        }));
    }

    const isDisabled = () => {
        if (isWhitelisted && !claimingNft){
            return false;
        }

        if (isWhitelisted && claimingNft){
            return true;
        }

        if (!isWhitelisted){
            return true;
        }
    }

    const buttonText = () => {
        if (isWhitelisted && !claimingNft){
            return "MINT";
        }

        if (isWhitelisted && claimingNft){
            return "BUSY";
        }

        if (!isWhitelisted){
            return "NOT WHITELISTED";
        }
    }

    useEffect(() => {
        trackPageView();
        config();
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockchain.account]);

    return (
        <>
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container maxWidth="sm">
                    <Div>
                        <HeaderTextWrapper>
                            Mint a Gear Bag
                        </HeaderTextWrapper>
                    </Div>
                    <Typography variant="caption" align="center" color="text.secondary" paragraph>
                        Whitelist - Get a free mint
                    </Typography>

                </Container>
            </Box>
            {blockchain.account === "" || blockchain.smartContract === null ? (
                <Connect />
            ) : (

                <Div>
                    <ComponentWrapper>
                        <MintWrapper>
                            {Number(data.totalSupply) === 10000 ? (
                                <MintActionWrapper>
                                    <Typography variant="h1" component="h2">
                                        All tokens have been minted
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        You can view the existing tokens, gear for sale, and check 3rd party marketplaces
                                    </Typography>
                                </MintActionWrapper>
                            ) : (
                                <MintActionWrapper>
                                    <Typography variant="h6">
                                        No cost - whitelist mints are free.
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        Excluding gas fee.
                                    </Typography>

                                    <Button
                                        variant="contained"
                                        disabled={isDisabled()}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            claimNFTs(1);
                                        }}
                                    >
                                        {buttonText()}
                                    </Button>
                                </MintActionWrapper>
                            )}
                            <TokenWrapper>
                                {data.displayToken?.name !== 'Example' ? (
                                    <DisplayToken token={data.displayToken} />
                                ) : (
                                    <>
                                        <Typography variant="h1" component="h2">You have no NFTs</Typography>
                                        <Typography variant="subtitle1">After minting, your gear bag will be displayed here.</Typography>
                                    </>
                                )}
                            </TokenWrapper>
                        </MintWrapper>
                    </ComponentWrapper>
                </Div>
            )}
        </>
    );
}

export default WhitelistMint;