import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Switch,
  Route,
  Link
} from "react-router-dom";
import styled from 'styled-components';
import tw from "tailwind-styled-components"
import "@fontsource/press-start-2p";
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'


import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import LoyaltyIcon from '@mui/icons-material/Loyalty';

import LogoSvg from './assets/images/logo.svg';
import config from './config.json';

import {
  setIsLive
} from "./redux/data/dataActions";

import Home from "./components/Home";
import Mint from "./components/Mint";
import WhitelistMint from "./components/WhitelistMint";
import OwnerMint from "./components/OwnerMint";
import OwnerWhitelist from "./components/OwnerWhitelist";
import OwnerWithdraw from "./components/OwnerWithdraw";
import TradeFlow from "./components/TradeFlow";
import Faq from "./components/Faq";
import SetPrices from "./components/SetPrices";
import MyTokens from "./components/MyTokens";
import ViewPrices from "./components/ViewPrices";
import ViewSingleToken from "./components/ViewSingleToken";
import Notification from "./components/Notification";
import LongRunningRequestSpinner from "./components/LongRunningRequestSpinner";
import MobileNotifyModal from "./components/MobileNotifyModal";
import "@fontsource/press-start-2p";

export const FullWidth = styled.div`
    width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

// function Copyright() {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" to="https://gearslots.com/">
//         Gear Slots
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

function ContactLinks() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      <a href={`https://polygonscan.com/address/${config.contractAddress}`} target="_blank" rel="noreferrer">
        Contract
      </a>{' | '}
      <a href="https://discord.gg/C7KwY4dySC" target="_blank" rel="noreferrer">
        Discord
      </a>{' | '}
      <a href="https://twitter.com/gearslots" target="_blank" rel="noreferrer">
        Twitter
      </a>{' | '}
      <a href="https://opensea.io/assets/matic/gearslots" target="_blank" rel="noreferrer">
        OpenSea
      </a>
    </Typography>
  );
}

const theme = createTheme();

export const Div = styled.div`
  font-family: "Press Start 2P";
`;

export const Span = styled.span`
  font-family: "Press Start 2P";
`;

export const Img = tw.img`
  w-2/5
  mb-2
`;

export const ImgWrap = tw.div`
  flex
  flex-col
  justify-center
  items-center
  mb-2
`;

function App() {
  const dispatch = useDispatch();
  const [sideNavState, setSideNavState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const matomoInstance = createInstance({
    urlBase: 'https://gearslots.matomo.cloud',
    siteId: 1,
    disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: { // optional, enabled by default
      active: true, // optional, default value: true
      seconds: 10 // optional, default value: `15
    },
    linkTracking: false, // optional, default value: true
    configurations: { // optional, default value: {}
      // any valid matomo configuration, all below are optional
      disableCookies: true,
      setSecureCookie: true,
      setRequestMethod: 'POST'
    }
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    //setState({ ...state, ['left']: open });
    setSideNavState({ ...sideNavState, right: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >

      <List>
        <ImgWrap>
          <Img
            alt="Gear Slot Logo"
            src={LogoSvg}
          />
          <Div>
            Gear Slots
          </Div>
        </ImgWrap>
        <Divider />
        <Link to="/">
          <ListItem button key={'Home'}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Home'} />
          </ListItem>
        </Link>

        <Link to="/mint">
          <ListItem button key={'Mint'}>
            <ListItemIcon>
              <AccountBalanceIcon />
            </ListItemIcon>
            <ListItemText primary={'Mint'} />
          </ListItem>
        </Link>

        <Link to="/mybags">
          <ListItem button key={'My Bags'}>
            <ListItemIcon>
              <ShoppingBagIcon />
            </ListItemIcon>
            <ListItemText primary={'My Bags'} />
          </ListItem>
        </Link>

        <Divider />

        <Link to="/trade">
          <ListItem button key={'Trade'}>
            <ListItemIcon>
              <SwapHorizIcon />
            </ListItemIcon>
            <ListItemText primary={'Trade'} />
          </ListItem>
        </Link>

        <Link to="/viewprices">
          <ListItem button key={'For Sale'}>
            <ListItemIcon>
              <LoyaltyIcon />
            </ListItemIcon>
            <ListItemText primary={'For Sale'} />
          </ListItem>
        </Link>

        <Divider />

        <Link to="/faq">
          <ListItem button key={'FAQ'}>
            <ListItemIcon>
              <HelpOutlineIcon />
            </ListItemIcon>
            <ListItemText primary={'FAQ'} />
          </ListItem>
        </Link>
      </List>
    </Box>
  );

  useEffect(() => {
    dispatch(setIsLive(config.isLive));
  }, [dispatch]);

  return (
    <MatomoProvider value={matomoInstance}>
      {isMobile ? (
        <MobileNotifyModal />
      ) : (
        <></>
      )}
      <ThemeProvider theme={theme}>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <LongRunningRequestSpinner />
        <CssBaseline />
        <AppBar position="relative">
          <Toolbar sx={{
            flexDirection: 'row-reverse'
          }}>
            <MenuIcon onClick={toggleDrawer('right', true)} />
            <Drawer
              anchor={'right'}
              open={sideNavState['right']}
              onClose={toggleDrawer('right', false)}
            >{list('right')}
            </Drawer>
          </Toolbar>
        </AppBar>
        <main>



          {/* A <Switch> looks through its children <Route>s and
renders the first one that matches the current URL. */}
          <FullWidth>
            <Switch>
              <Route path="/faq">
                <Faq />
              </Route>
              <Route path="/mint">
                <Mint />
              </Route>
              <Route path="/ownermint">
                <OwnerMint />
              </Route>
              <Route path="/ownerwhitelist">
                <OwnerWhitelist />
              </Route>
              <Route path="/ownerwithdraw">
                <OwnerWithdraw />
              </Route>
              <Route path="/whitelistmint">
                <WhitelistMint />
              </Route>
              <Route path="/trade">
                <TradeFlow />
              </Route>
              <Route path="/setprices/:tokenId">
                <SetPrices />
              </Route>
              <Route path="/mybags">
                <MyTokens />
              </Route>
              <Route path="/viewprices">
                <ViewPrices />
              </Route>
              <Route path="/viewbag/:tokenId">
                <ViewSingleToken />
              </Route>
              <Route path="/20AF6DCB-EE97-47E1-AAB7-EC73241A0850">
                <Mint />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </FullWidth>
        </main>
        {/* Footer */}
        <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
          <Typography variant="h6" align="center" gutterBottom>
            {/* Gear Slots */}
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            color="text.secondary"
            component="p"
          >
          </Typography>
          {/* <Copyright /> */}
          <ContactLinks />
        </Box>
        {/* End footer */}
        <Notification />
      </ThemeProvider>
    </MatomoProvider>
  );
}

export default App;
