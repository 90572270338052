import React from "react";
import {
    Link
} from "react-router-dom";
import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
`;

export const HeaderTextWrapper = styled.div`
    font-family: "Press Start 2P";
    font-size: x-large;
    margin-top: 20px;

    @media only screen and (min-width: 767px){
        font-size: xx-large;
    }
`;

export const ContainerDiv = tw.div`
    flex
    flex-col
    w-full
    lg:w-3/4
`;

function Faq(props) {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Container maxWidth="md">
            <Div>
                <HeaderTextWrapper>
                    FAQ
                </HeaderTextWrapper>
            </Div>
            <Typography variant="caption" align="center" color="text.secondary" paragraph>
                You have questions, we have answers.
            </Typography>
            <div>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ color: 'text.secondary' }}>I can trade individual pieces of gear from my bag?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography paragraph={true}>
                            Yes!
                        </Typography>
                        <Typography paragraph={true}>
                            Gear Slots NFT are bags of gear. Each bag contains 9 "slots": weapon, shield, chest
                            armor, helm, boots, gloves, belt, ring, and necklace.
                        </Typography>
                        <Typography paragraph={true}>
                            Each slot can be traded for the same type of slot with another token. The slots are
                            stored on the blockchain as attributes of the token. Since you can trade gear between
                            tokens, you can trade attributes.
                        </Typography>
                        <Typography>
                            You can set a sale price for each piece of gear within your bag. When you set a price, your item
                            will be visible when other users search for items to buy.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <Typography sx={{ color: 'text.secondary' }}>
                            How do I trade pieces of gear in one of my bags?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography paragraph={true}>
                            Head on over to the <Link to="/viewprices">For Sale</Link> pages.
                        </Typography>
                        <Typography paragraph={true}>
                            Browse the gear that is available for sale. Once you find a piece
                            you're interested in, simply click the item to begin the trading process.
                        </Typography>
                        <Typography paragraph={true}>
                            On the next screen, you are shown the item you clicked on, as well as the
                            price in MATIC for that item.
                        </Typography>
                        <Typography paragraph={true}>
                            Click Next to continue the process. On the next screen, you are shown a list of the bags
                            that you own. Here, you select the bag that you would like the previously selected
                            item to go into, once the trade is complete.

                            Select the bag by clicking the blue button at the bottom of the bag.
                        </Typography>
                        <Typography paragraph={true}>
                            The next screen is a preview of the trade. You will trade the amount of MATIC,
                            as well as the item shown on the screen, for the item you selected earlier.
                        </Typography>
                        <Typography paragraph={true}>
                            For example, if you pick a sword with a price of 4 MATIC, the sword you picked will be swapped
                            with the weapon from your bag that you chose during the trade process. You will pay a fee of 4 MATIC
                            for the sword as well.
                        </Typography>
                        <Typography paragraph={true}>
                            When you continue and click the Execute Trade button, MetaMask will show the details of the transaction,
                            and prompt for your approval.
                        </Typography>
                        <Typography paragraph={true}>
                            When you approve the transaction in MetaMask, you will receive a confirmation prompt
                            from your wallet. The price of the item will be shown here, as well as on the
                            for sale page.
                        </Typography>
                        <Typography paragraph={true}>
                            The trade is executed instantly after your confirmation. View your bags to see your new gear!
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <Typography sx={{ color: 'text.secondary' }}>
                            How do I set the price for a piece of my gear?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography paragraph={true}>
                            When you are viewing your bags, each bag contains a "Set Prices" button at the bottom
                            of the bag. Click that link to be brought to the set prices screen.
                        </Typography>
                        <Typography paragraph={true}>
                            Once on the set prices screen, click the pencil icon to edit the prices for each item.
                            When you have set the prices, click the save icon on the top right. This saves the prices
                            in your browser, but does not save them to the blockchain.
                        </Typography>
                        <Typography paragraph={true}>
                            To save the prices to the blockchain, click the submit button. There is no fee charged for
                            save prices, excluding gas.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <Typography sx={{ color: 'text.secondary' }}>
                            Is there a gear trading fee?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography paragraph={true}>
                            Yes, 5% of the price set by the seller goes to the contract developers.
                            This fee comes out of the seller's funds.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <Typography sx={{ color: 'text.secondary' }}>When setting prices, must I set a price for every item?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography paragraph={true}>
                            No. When you are setting prices, you may set the price of a single slot, every slot, or any number of slots you choose.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <Typography sx={{ color: 'text.secondary' }}>Is there a fee for setting item prices?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography paragraph={true}>
                            No, there is no fee. You pay only gas when setting prices.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <Typography sx={{ color: 'text.secondary' }}>Where are the tokens stored?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography paragraph={true}>
                            The tokens are stored completely on the Polygon blockchain. There is no data stored off-chain, everything is
                            stored completely on-chain.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <Typography sx={{ color: 'text.secondary' }}>What is the mint price?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography paragraph={true}>
                            10.0 MATIC
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <Typography sx={{ color: 'text.secondary' }}>Can I list my token for sale on Open Sea?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography paragraph={true}>
                            Yes! You can list your token for sale on any marketplace that supports ERC721 tokens.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <Typography sx={{ color: 'text.secondary' }}>How can I contact you?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography paragraph={true}>
                            You can find us on Twitter @gearslots, and Discord at https://discord.gg/FErU8xU8tp
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <Typography sx={{ color: 'text.secondary' }}>When do you drop?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography paragraph={true}>
                            October, 2021
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <Typography sx={{ color: 'text.secondary' }}>What is the whitelist?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography paragraph={true}>
                            The whitelist is a list of addresses that are allowed a single, free mint.
                        </Typography>
                        <Typography paragraph={true}>
                            The minter must still pay gas, but the normal 10.0 MATIC fee is waived.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>

        </Container>
    );
}

export default Faq