import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom'
import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import { useMatomo } from '@datapunt/matomo-tracker-react'

import MaterialTable, { MTableBodyRow } from '@material-table/core';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
    createTheme,
    ThemeProvider
} from '@mui/material/styles';

import {
    setTradeSlotId,
    setTargetTradeData,
    setActiveTradeStep
} from '../redux/data/dataActions';

export const SlotSelectorWrapper = tw.div`
    flex
    w-full
    justify-evenly
    items-center
    flex-wrap
`;

export const CardWrapper = tw.div`
    flex
    w-full
    justify-center
    align-center
`;

export const ButtonWrapper = styled.div`
    width: 80px;
    margin: 2px;
`;

function DisplayPriceList(props) {

    const prices = useSelector((state) => state.data.slotPrices);
    const slotName = useSelector((state) => state.data.viewSlotName);
    const slotId = useSelector((state) => state.data.viewSlotId);
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const title = `${slotName} for sale`;
    const theme = createTheme();
    const tableName = '';
    const history = useHistory();
    //const [rowData, setRowData] = useState(null);
    const { trackEvent } = useMatomo();

    const formatPriceForTable = (price) => {

        const row = {
            bagNumber: price.tokenId,
            itemName: price.slotItem,
            price: blockchain.web3.utils.fromWei(price.price, 'ether'),
            currency: 'MATIC'
        };

        return row;
    }

    const changeSlot = (e) => {
        trackEvent({ category: 'display-price-list', action: `change-slot-${e.currentTarget.id}` });
        switch (e.currentTarget.id) {
            case 'weapon':
                dispatch(setTradeSlotId(0, blockchain.account));
                break;
            case 'shield':
                dispatch(setTradeSlotId(8, blockchain.account));
                break;
            case 'chest':
                dispatch(setTradeSlotId(1, blockchain.account));
                break;
            case 'helm':
                dispatch(setTradeSlotId(2, blockchain.account));
                break;
            case 'belt':
                dispatch(setTradeSlotId(3, blockchain.account));
                break;
            case 'boots':
                dispatch(setTradeSlotId(4, blockchain.account));
                break;
            case 'gloves':
                dispatch(setTradeSlotId(5, blockchain.account));
                break;
            case 'neck':
                dispatch(setTradeSlotId(6, blockchain.account));
                break;
            case 'ring':
                dispatch(setTradeSlotId(7, blockchain.account));
                break;
            default:
                break;
        }
    }

    // const handleDoubleClick = (event) => {
    //     dispatch(setActiveTradeStep(0));
    //     dispatch(setTargetTradeData(rowData));
    //     dispatch(setActiveTradeStep(0));
    //     history.push("/trade");
    //     dispatch(setActiveTradeStep(0));
    // }

    const handleClick = (event, rowData) => {
        trackEvent({ category: 'display-price-list', action: 'handle-click' });
        //setRowData(rowData);

        // added this in when moving from double click to single click
        dispatch(setActiveTradeStep(0));
        dispatch(setTargetTradeData(rowData));
        history.push("/trade");
    }

    return (
        <>
            <CardWrapper>
                <Card sx={{
                    width: '100%',
                    [theme.breakpoints.up('lg')]: {
                        width: '70%',
                    },
                }} id={('slot_' + slotId)}>
                    <CardHeader
                        title={title}
                        subheader="Gear from bags you own will not be displayed"
                    />
                    <CardContent>
                        <ThemeProvider theme={theme}>
                            <MaterialTable
                                tableLayout="fixed"
                                options={{
                                    pageSize: 10,
                                    paging: true,
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [10, 20, 50, 100, 500],
                                    actionsColumnIndex: -1,
                                }}
                                columns={[
                                    {
                                        title: 'Item Name',
                                        field: 'itemName',
                                        width: "80%"
                                    },
                                    {
                                        title: 'Price',
                                        field: 'price',
                                        width: "10%",
                                        type: 'numeric'
                                    },
                                    {
                                        title: 'Currency',
                                        field: 'currency',
                                        width: "5%",
                                        sorting: false
                                    },
                                ]}
                                components={{
                                    Row: props => <MTableBodyRow
                                        {...props}
                                        onRowClick={handleClick}
                                        //onDoubleClick={handleDoubleClick}
                                    />
                                }}
                                data={prices.map(formatPriceForTable)}
                                title={tableName}
                            />
                        </ThemeProvider>
                    </CardContent>
                    <CardActions>

                        <SlotSelectorWrapper>
                            <ButtonWrapper>
                                <Button
                                    id='weapon'
                                    variant={(slotId === 0 ? 'contained' : 'outlined')}
                                    onClick={changeSlot}
                                    sx={{ width: '100%' }}
                                >
                                    <Typography variant="caption">Weapons</Typography>
                                </Button>
                            </ButtonWrapper>
                            <ButtonWrapper>
                                <Button
                                    id='shield'
                                    variant={(slotId === 8 ? 'contained' : 'outlined')}
                                    onClick={changeSlot}
                                    sx={{ width: '100%' }}
                                >
                                    <Typography variant="caption">Shield</Typography>
                                </Button>
                            </ButtonWrapper>
                            <ButtonWrapper>
                                <Button
                                    id='chest'
                                    variant={(slotId === 1 ? 'contained' : 'outlined')}
                                    onClick={changeSlot}
                                    sx={{ width: '100%' }}
                                >
                                    <Typography variant="caption">Chest</Typography>
                                </Button>
                            </ButtonWrapper>
                            <ButtonWrapper>
                                <Button
                                    id='helm'
                                    variant={(slotId === 2 ? 'contained' : 'outlined')}
                                    onClick={changeSlot}
                                    sx={{ width: '100%' }}
                                >
                                    <Typography variant="caption">Helm</Typography>
                                </Button>
                            </ButtonWrapper>
                            <ButtonWrapper>
                                <Button
                                    id='belt'
                                    variant={(slotId === 3 ? 'contained' : 'outlined')}
                                    onClick={changeSlot}
                                    sx={{ width: '100%' }}
                                >
                                    <Typography variant="caption">Belts</Typography>
                                </Button>
                            </ButtonWrapper>
                            <ButtonWrapper>
                                <Button
                                    id='boots'
                                    variant={(slotId === 4 ? 'contained' : 'outlined')}
                                    onClick={changeSlot}
                                    sx={{ width: '100%' }}
                                >
                                    <Typography variant="caption">Boots</Typography>
                                </Button>
                            </ButtonWrapper>
                            <ButtonWrapper>
                                <Button
                                    id='gloves'
                                    variant={(slotId === 5 ? 'contained' : 'outlined')}
                                    onClick={changeSlot}
                                    sx={{ width: '100%' }}
                                >
                                    <Typography variant="caption">Gloves</Typography>
                                </Button>
                            </ButtonWrapper>
                            <ButtonWrapper>
                                <Button
                                    id='neck'
                                    variant={(slotId === 6 ? 'contained' : 'outlined')}
                                    onClick={changeSlot}
                                    sx={{ width: '100%' }}
                                >
                                    <Typography variant="caption">Neck</Typography>
                                </Button>
                            </ButtonWrapper>
                            <ButtonWrapper>
                                <Button
                                    id='ring'
                                    variant={(slotId === 7 ? 'contained' : 'outlined')}
                                    onClick={changeSlot}
                                    sx={{ width: '100%' }}
                                >
                                    <Typography variant="caption">Ring</Typography>
                                </Button>
                            </ButtonWrapper>
                        </SlotSelectorWrapper>
                    </CardActions>
                </Card>
            </CardWrapper>
        </>
    );







    //const pricesDisplay = [];
    // for (const price of props?.prices) {
    //     const key = `${price[0]}${price[1]}`;
    //     pricesDisplay.push(<DisplayPrice key={key} price={price}></DisplayPrice>);
    // }

    // return (
    //     <>
    //         {pricesDisplay}
    //     </>
    // );
}

export default DisplayPriceList;