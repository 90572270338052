import React from "react";
import { useSelector } from "react-redux";
import { useHistory, Link } from 'react-router-dom';
import tw from "tailwind-styled-components"
import styled from "styled-components";

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import Dropping from "./Dropping"

import SampleBag from '../assets/images/samplebag.svg';
// import RoadMap from "./RoadMap";

export const ButtonWrapper = tw.div`
    w-1/2
    lg:w-1/4
`;

export const Div = styled.div`
    font-family: "Press Start 2P";
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: xxx-large;
`;

export const ItemHeader = styled.div`
    font-family: "Press Start 2P";
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: large;
    margin-top: 25px;
    margin-bottom: 15px;
`;

export const DetailDiv = tw.div`
    flex
    flex-col
    justify-center
    items-center
    lg:flex-row
`;

export const TokenDiv = tw.div`
    w-full
    mb-5
    md:w-2/5
    md:m-10
`;

function Home(props) {

    const history = useHistory();
    const isLive = useSelector((state) => state.data.isLive);

    return (

        <main>
            {/* Hero unit */}
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container maxWidth="md">
                    <Div>
                        Gear Slots
                    </Div>
                    {/* temp placeholder before launch*/}
                    {isLive ? (
                        <>
                        </>
                    ) : (
                        <Dropping />
                    )}
                    {/* temp placeholder before launch */}
                    <Typography variant="h5" align="center" color="text.secondary" paragraph>
                        Bags of gear. Stored completely on the Polygon blockchain, usable everywhere.
                    </Typography>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        Gear Slots is on-chain items for games. Any games. Any platform. Online games.
                        Offline games. Tabletop games. You decide where you use your Game Slots. Stored completely on-chain.
                    </Typography>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        Each Game Slots token is composed of slots. Each slot is tradeable with like slots from other tokens.
                        Need the perfect sword for your weapon slot? Browse the weapons available for trade.
                    </Typography>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        Roll a great weapon? Either use it, or set a price and put it up for trade. Other collectors can find it and trade with you.
                    </Typography>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        Gear Slots gives the building blocks for games - each token can be used independently, or combined with other tokens.
                        The specifics of each slot item such as intelligence, hit points, mana, etc, are intentially left out for maximum interoperability.
                    </Typography>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        The tokens are stored completely on-chain. No IPFS, no files on off-chain resources that can be manipulated. All tokens are stored completely on-chain.
                    </Typography>

                    <Divider />

                    <Stack
                        sx={{
                            pt: 4
                        }}
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                    >
                        <ButtonWrapper>
                            <Button
                                sx={{ width: '100%' }}
                                variant="contained"
                                endIcon={<AccountBalanceIcon />}
                                onClick={() => history.push('/mint')}
                            >Mint</Button>
                        </ButtonWrapper>
                        <ButtonWrapper>
                            <Button
                                sx={{ width: '100%' }}
                                variant="contained"
                                endIcon={<SwapHorizIcon />}
                                onClick={() => history.push('/trade')}
                            >Trade</Button>
                        </ButtonWrapper>
                    </Stack>

                    <Stack
                        sx={{ pt: 4 }}
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                    >
                        <ButtonWrapper>
                            <Button
                                sx={{ width: '100%' }}
                                variant="contained"
                                endIcon={<ShoppingBagIcon />}
                                onClick={() => history.push('/mybags')}
                            >My Bags</Button>
                        </ButtonWrapper>
                        <ButtonWrapper>
                            <Button
                                sx={{ width: '100%' }}
                                variant="contained"
                                endIcon={<LoyaltyIcon />}
                                onClick={() => history.push('/viewprices')}
                            >For Sale</Button>
                        </ButtonWrapper>
                    </Stack>

                    <Stack
                        sx={{ pt: 4 }}
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                    >
                        <ButtonWrapper>
                            <Button
                                sx={{
                                    width: '100%',
                                    marginBottom: '25px'
                                }}
                                variant="contained"
                                endIcon={<HelpOutlineIcon />}
                                onClick={() => history.push('/faq')}
                            >FAQ</Button>
                        </ButtonWrapper>
                    </Stack>

                    <Divider />

                    {/* <RoadMap
                        bgColor="default"
                        size="medium"
                        bgImage=""
                        bgImageOpacity={1}
                        title="Features"
                        subtitle="All the features you need to move faster"
                    /> */}

                    <ItemHeader>
                        How It Works
                    </ItemHeader>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        You <Link to="/mint">mint</Link> a bag to get started. Gear Slot Character Sets are the first deployed.
                    </Typography>
                    <div xs={12} md={6} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Typography align="center" color="text.secondary" variant="body1" component="div">
                            When you mint, your token includes 9 slots of gear:
                        </Typography>
                        <DetailDiv>
                            <List>
                                <ListItem sx={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    <ListItemText
                                        primary="Weapon"
                                    />
                                    <ListItemText
                                        primary="Shield"
                                    />
                                    <ListItemText
                                        primary="Chest Armor"
                                    />
                                    <ListItemText
                                        primary="Helm"
                                    />
                                    <ListItemText
                                        primary="Belt"
                                    />
                                    <ListItemText
                                        primary="Boots"
                                    />
                                    <ListItemText
                                        primary="Gloves"
                                    />
                                    <ListItemText
                                        primary="Neckpiece"
                                    />
                                    <ListItemText
                                        primary="Ring"
                                    />
                                </ListItem>
                            </List>
                            <TokenDiv>
                                <img
                                    src={SampleBag}
                                    alt="Sample Bag" />
                            </TokenDiv>
                        </DetailDiv>

                    </div>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        Each slot is filled with a unique item that is generated randomly at the time of minting.
                    </Typography>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        Upgrade each slot by trading for items other token holders have put up for sale, <Link to="/forsale">here.</Link>
                    </Typography>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        List your own gear for sale. List a single slot, all 9, or any combination. There is no fee for setting sale prices, only gas.
                    </Typography>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        There is a total supply of 50,000 bags.
                    </Typography>

                    <Divider />

                    <ItemHeader>
                        Item Rarity
                    </ItemHeader>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        When a bag is minted, each item is randomly generated. The most common items are exactly that - common.
                        Each slot, on each bag, has a chance at being excellent.
                    </Typography>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        Every slot can have a prefix and a suffix. Prefixes and suffixes follow the same pattern as the items themselves - there are common, rare, ultrarare.
                    </Typography>

                    <Divider />

                    <ItemHeader>
                        Trading Slots
                    </ItemHeader>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        Each slot is tradeable with the same slot from other bags. Want to upgrade your weapon?
                        Browse weapons for sale <Link to="/forsale">here.</Link>
                        The seller of the item sets the price for that item.
                        Click or tap on the item you'd like to purchase.
                    </Typography>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        On the next screen, all the bags you own are displayed.
                        Select the bag you want that into to go in to. The item you selected will be swapped for the same slot item from the bag you select.
                    </Typography>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        The final screen is a review. The price on the trade is set by the seller of the item.
                        When you accept the trade, you will be prompted to confirm in your wallet.
                    </Typography>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        Trades are executed immediately. The blockchain is updated - the item you purchased is now in your bag, and your old item is in the seller's bag.
                    </Typography>

                    <Divider />

                    <ItemHeader>
                        Setting Prices
                    </ItemHeader>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        To set a price on a slot, start by viewing all your bags at <Link to="/mybags">My bags.</Link>
                        Each bag contains a link to Set Prices for that bag.
                    </Typography>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        Once on the Set Prices screen, you can set the price for a single slot, or all slots.
                    </Typography>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        There is no fee to set prices. You pay only the gas required to process the transaction.
                    </Typography>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        There is a 5% fee on all sales. When your item sells, you will receive 95% of the price you set on the items.
                        You will also recieve the slot item from the buyer. This way, your bag is always a complete set of gear.
                    </Typography>

                    <Divider />

                    <ItemHeader>
                        Provably Fair
                    </ItemHeader>
                    <Typography variant="body1" align="center" color="text.secondary" paragraph>
                        There was no pre-sale, no tokens are reserved, no special actions prior to the launch. At launch, everyone has a fair chance to mint.
                    </Typography>

                </Container>
            </Box>
            <Container sx={{ py: 8 }} maxWidth="md">
                {/* End hero unit */}

                { /* this is where minted bags will go */}
                {/* <Grid container spacing={4}>
                    {cards.map((card) => (
                        <Grid item key={card} xs={12} sm={6} md={4}>
                            <Card
                                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                            >
                                <CardMedia
                                    component="img"
                                    sx={{
                                        // 16:9
                                        pt: '56.25%',
                                    }}
                                    image="https://source.unsplash.com/random"
                                    alt="random"
                                />
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Heading
                                    </Typography>
                                    <Typography>
                                        This is a media card. You can use this section to describe the
                                        content.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small">View</Button>
                                    <Button size="small">Edit</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid> */}
            </Container>
        </main>
    );
}

export default Home