import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import {
    fetchOwnerTokenIds,
    updateBlockchainSpinner
} from "../redux/data/dataActions";

import Connect from "./Connect";

import DisplayTokensList from "./DisplayTokensList";

export const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
`;

export const HeaderTextWrapper = styled.div`
    font-family: "Press Start 2P";
    font-size: x-large;

    @media only screen and (min-width: 767px){
        font-size: xx-large;
    }
`;


function MyTokens(props) {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const tokens = useSelector((state) => state.data.myTokens);
    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(updateBlockchainSpinner(true));
            dispatch(fetchOwnerTokenIds(blockchain.account, true));
        }
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        blockchain.account,
        blockchain.smartContract
    ]);

    return (
        <>
            <Div>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="sm">
                        <Div>
                            <HeaderTextWrapper>
                                My Bags
                            </HeaderTextWrapper>
                        </Div>
                        <Typography variant="caption" align="center" color="text.secondary">
                            These are the gear bags you own
                        </Typography>
                        <Typography variant="caption" align="center" color="text.secondary" paragraph>
                            {tokens.length > 0 ? (
                                'You own ' + tokens.length + ' bags'
                            ) : (
                                'You do not own any bags'
                            )}
                        </Typography>

                    </Container>
                </Box>
            </Div>
            {blockchain.account === "" || blockchain.smartContract === null ? (
                <Connect />
            ) : (
                <Div>
                    {tokens.length > 0 ? (
                        <DisplayTokensList tokens={tokens} />
                    ) : (
                        <Container maxWidth="md">
                            <Typography align="center" color="text.primary">
                                <Link to="/mint">You do not own any bags. Go mint some!</Link>
                            </Typography>
                        </Container>
                    )}
                </Div>
            )}
        </>
    )
}

export default MyTokens;