import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom'
import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import { useMatomo } from '@datapunt/matomo-tracker-react'


import {
    fetchSingleToken,
    fetchTokenPrices,
    updatePrice,
    showNotification,
    setItemsAndPrices
} from "../redux/data/dataActions";

import MaterialTable from '@material-table/core';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import {
    createTheme,
    ThemeProvider
} from '@mui/material/styles';

import Connect from "./Connect";

export const HeaderTextWrapper = styled.div`
    font-family: "Press Start 2P";
    font-size: x-large;

    @media only screen and (min-width: 767px){
        font-size: xx-large;
    }
`;

export const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
`;

export const Grid = styled.div`
    font-size: small;
    width: 100%;
    @media (min-width: 767px){
        width: 75%;
    }
`;

export const Row = styled.div`
    display: flex;
    margin-top: 11px;
    width: 100%;
    justify-content: end;
    align-items: center;
    &:hover {
        background-color: lightblue;
      }    
`;

export const Col = styled.div`    
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
`;

export const Input = styled.input`
`;

export const MarginDiv = styled.div`
    margin-left: 5px;
`;

export const ButtonWrapper = tw.div`
      flex
      w-full
      justify-center
      lg:justify-end

      mr-0
      lg:mr-48
`;

export const CardWrapper = tw.div`
    flex
    w-full
    justify-center
    align-center
`;

function SetPrices(props) {
    const { tokenId } = useParams();
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const prices = useSelector((state) => state.data.selectedTokenPrices);
    const tableName = "";
    const title = `Edit Bag #${tokenId} Prices`;
    const minPrice = 0.00001;
    const theme = createTheme();
    const { trackPageView, trackEvent } = useMatomo();

    const getToken = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchSingleToken(tokenId));
        }
    };

    const getTokenPrices = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchTokenPrices(tokenId));
        }
    };

    useEffect(() => {
        trackPageView();
        getToken();
        getTokenPrices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockchain.account]);

    return (
        <div>
            {blockchain.account === "" || blockchain.smartContract === null ? (
                <Connect />
            ) : (
                <Div>
                    <Box
                        sx={{
                            bgcolor: 'background.paper',
                            pt: 8,
                            pb: 6,
                        }}
                    >
                        <Container maxWidth="sm">
                            <Div>
                                <HeaderTextWrapper>
                                    Set Slot Prices
                                </HeaderTextWrapper>
                            </Div>
                            <Typography variant="caption" align="center" color="text.secondary" paragraph>
                                Set the price on a single piece of gear, or all pieces of gear.
                            </Typography>
                        </Container>
                    </Box>
                    <CardWrapper>
                        <Card sx={{
                            width: '100%',
                            [theme.breakpoints.up('lg')]: {
                                width: '70%',
                            },
                        }} id={('token_' + tokenId)}>
                            <CardHeader
                                title={title}
                                subheader="Submit after editing prices"
                            />
                            <CardContent>
                                <ThemeProvider theme={theme}>
                                    <MaterialTable
                                        tableLayout="fixed"
                                        editable={{
                                            onBulkUpdate: changes => {
                                                return new Promise((resolve, reject) => {
                                                    trackEvent({ category: 'set-prices', action: 'save-prices-clicked' });
                                                    for (const changeKey in changes) {
                                                        const change = changes[changeKey];
                                                        if (change.newData.price < minPrice) {
                                                            dispatch(showNotification({
                                                                open: true,
                                                                message: `Minimum price is ${minPrice} MATIC.`,
                                                                severity: "warning"
                                                            }));               
                                                            trackEvent({ category: 'set-prices', action: 'min-price-not-met' });
                                                            return reject();
                                                        } else {
                                                            dispatch(updatePrice(change.newData.propertyName, change.newData.price));
                                                        }
                                                    }
                                                    /// **************************************************************************
                                                    // this is where we can save everything                                       
                                                    
                                                    dispatch(setItemsAndPrices()).then(
                                                        (itemsAndPrices) => {
                                                            if (itemsAndPrices.itemArray.length === 0) {
                                                                trackEvent({ category: 'set-prices', action: 'no-changes' });
                                                                resolve();
                                                            } else {
                                                                dispatch(showNotification({
                                                                    open: true,
                                                                    message: "Updating token prices",
                                                                    severity: "info"
                                                                }));
                                                        
                                                                blockchain.smartContract.methods
                                                                    .setSlotPrices(
                                                                        tokenId,
                                                                        itemsAndPrices.itemArray,
                                                                        itemsAndPrices.priceArray)
                                                                    .send({
                                                                        from: blockchain.account,
                                                                    })
                                                                    .once("error", (err) => {
                                                                        console.log(err);
                                                                        dispatch(showNotification({
                                                                            open: true,
                                                                            message: "Sorry, something went wrong please try again later.",
                                                                            severity: "error"
                                                                        }));
                                                                        trackEvent({ category: 'set-prices', action: 'wallet-rejection' });
                                                                        return reject();
                                                                    })
                                                                    .then((receipt) => {
                                                                        dispatch(showNotification({
                                                                            open: true,
                                                                            message: "Prices updated!",
                                                                            severity: "success"
                                                                        }));
                                                                        trackEvent({ category: 'set-prices', action: 'prices-update-success' });
                                                                        return resolve();
                                                                    });                 
                                                            }  
                                                        },
                                                        (err) => {
                                                            console.log(err);
                                                            dispatch(showNotification({
                                                                open: true,
                                                                message: "Sorry, something went wrong please try again later.",
                                                                severity: "error"
                                                            }));
                                                        }
                                                    );
                                                    // end save everythin to blockchain
                                                    /// **************************************************************************
                                                });
                                            },
                                        }}
                                        options={{
                                            pageSize: 10,
                                            paging: true,
                                            emptyRowsWhenPaging: false,
                                            pageSizeOptions: [10, 20, 50, 100, 500],
                                            actionsColumnIndex: -1,
                                        }}
                                        columns={[
                                            {
                                                title: 'Item Name',
                                                field: 'itemName',
                                                width: "80%",
                                                editable: 'never'
                                            },
                                            {
                                                title: 'Price',
                                                field: 'price',
                                                width: "10%"
                                            },
                                            {
                                                title: 'Currency',
                                                field: 'currency',
                                                width: "10%",
                                                editable: 'never',
                                                sorting: false
                                            },
                                        ]}
                                        data={[
                                            {
                                                propertyName: "weapon",
                                                itemName: data.selectedTokenData.weapon,
                                                price: prices.weapon,
                                                currency: 'MATIC'
                                            },
                                            {
                                                propertyName: "shield",
                                                itemName: data.selectedTokenData.shield,
                                                price: prices.shield,
                                                currency: 'MATIC'
                                            },
                                            {
                                                propertyName: "chest",
                                                itemName: data.selectedTokenData.chest,
                                                price: prices.chest,
                                                currency: 'MATIC'
                                            },
                                            {
                                                propertyName: "helm",
                                                itemName: data.selectedTokenData.helm,
                                                price: prices.helm,
                                                currency: 'MATIC'
                                            },
                                            {
                                                propertyName: "belt",
                                                itemName: data.selectedTokenData.belt,
                                                price: prices.belt,
                                                currency: 'MATIC'
                                            },
                                            {
                                                propertyName: "boots",
                                                itemName: data.selectedTokenData.boots,
                                                price: prices.boots,
                                                currency: 'MATIC'
                                            },
                                            {
                                                propertyName: "gloves",
                                                itemName: data.selectedTokenData.gloves,
                                                price: prices.gloves,
                                                currency: 'MATIC'
                                            },
                                            {
                                                propertyName: "neck",
                                                itemName: data.selectedTokenData.neck,
                                                price: prices.neck,
                                                currency: 'MATIC'
                                            },
                                            {
                                                propertyName: "ring",
                                                itemName: data.selectedTokenData.ring,
                                                price: prices.ring,
                                                currency: 'MATIC'
                                            },
                                        ]}
                                        title={tableName}
                                    />
                                </ThemeProvider>
                            </CardContent>
                        </Card>
                    </CardWrapper>

                </Div>
            )}
        </div>
    )
}

export default SetPrices;