// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

export const resetTradeBagsAfterTrade = () => {
  return {
    type: "RESET_TRADE_BAG_AFTER_TRADE"
  }
}

const fetchSingleTokenSuccess = (payload => {
  return {
    type: "FETCH_SINGLE_TOKEN_SUCCESS",
    payload: payload,
  };
});

const checkIfWhitelistedSuccess = (payload => {
  return {
    type: "UPDATE_IS_WHITELISTED",
    payload: payload,
  };
});

const updateItemsAndPrices = (payload => {
  return {
    type: "UPDATE_ITEMS_AND_PRICES",
    payload: payload
  };
});

export const showNotification = (payload => {
  return {
    type: "SHOW_NOTIFICATION",
    payload: payload
  };
});

export const closeNotification = (payload => {
  return {
    type: "CLOSE_NOTIFICATION"
  };
});

const setSelectedSourceBagData = (token => {
  return {
    type: "SET_SELECTED_SOURCE_BAG",
    payload: token
  };
});

const setSelectedSlotId = (slotId => {
  return {
    type: "SET_SELECTED_SLOT",
    payload: slotId
  };
});

const setSelectedSlotName = (slotName => {
  return {
    type: "SET_SELECTED_SLOT_NAME",
    payload: slotName
  };
});

const setSelectedTargetBag = (tokenId => {
  return {
    type: "SET_SELECTED_TARGET_BAG",
    payload: tokenId
  };
});

export const initTradeState = (payload => {
  return {
    type: "INIT_TRADE_STATE"
  };
});

export const updateBlockchainQueries = (payload => {
  return {
    type: "UPDATE_BLOCKCHAIN_QUREIES",
    payload: payload
  };
});

const setTradePrice = (price => {
  return {
    type: "SET_TRADE_PRICE",
    payload: price
  };
});

const updateTargetTradeData = (payload => {
  return {
    type: "UPDATE_TARGET_TRADE_DATA",
    payload: payload
  };
});

export const setMyTokensConfig = (config => {
  return {
    type: "SET_MYTOKENS_CONFIG",
    payload: config
  };
});

const fetchAllOwnerTokensSuccess = (payload => {
  return {
    type: "FETCH_ALL_OWNER_TOKENS_SUCCESS",
    payload: payload,
  };
});

const fetchOwnerTokenIdsSuccess = (payload => {
  return {
    type: "FETCH_OWNER_TOKEN_IDS_SUCCESS",
    payload: payload,
  };
});

const setIsLiveValue = (payload => {
  return {
    type: "SET_IS_LIVE",
    payload: payload,
  };
});

const fetchSingleDisplayTokenSuccess = (payload => {
  return {
    type: "FETCH_SINGLE_DISPLAY_TOKEN_SUCCESS",
    payload: payload
  };
});

const fetchTokenPricesSuccess = (payload => {
  return {
    type: "FETCH_TOKEN_PRICES_SUCCESS",
    payload: payload,
  };
});

const setActiveStep = (payload => {
  return {
    type: "SET_ACTIVE_TRADE_STEP",
    payload: payload
  };
});

export const updatePrice = ((slotName, price) => {
  return {
    type: "UPDATE_SLOT_PRICE",
    payload: {
      slotName: slotName,
      price: price
    }
  };
});

export const updateBlockchainSpinner = (payload) => {
  return {
    type: "UPDATE_BLOCKCHAIN_SPINNER",
    payload: payload
  };
};

const fetchSlotPricesSuccess = (payload) => {
  return {
    type: "FETCH_SLOT_PRICES_SUCCESS",
    payload: payload
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

const getSlotName = (slotId) => {
  switch (slotId) {
    case 0:
      return "Weapons";
    case 8:
      return "Shields";
    case 1:
      return "Chest Armor";
    case 2:
      return "Helms";
    case 3:
      return "Belts";
    case 4:
      return "Boots";
    case 5:
      return "Gloves";
    case 6:
      return "Neck Pieces";
    case 7:
      return "Rings";
    default:
      return "";
  }
}

export const setIsLive = (isLive => {
  return async (dispatch) => {
    dispatch(setIsLiveValue(isLive));
  };
});


export const setTradeSlotId = ((slotId, account) => {
  return async (dispatch) => {
    dispatch(setSelectedSlotId(slotId));

    // set the slot name
    const slotName = getSlotName(parseInt(slotId));
    dispatch(setSelectedSlotName(slotName));

    // fetch owner tokens
    dispatch(fetchOwnerTokenIds(account)).then(
      (tokenIds) => {
        dispatch(fetchSlotPrices(slotId, tokenIds));
      }
    );
  };
});

export const setTargetTradeData = ((data) => {
  return async (dispatch) => {
    const payload = {
      targetItemName: data.itemName,
      targetItemCostEth: data.price,
      selectedTargetBag: data.bagNumber
    }
    dispatch(updateTargetTradeData(payload));
  }
});

export const setSelectedSourceBagObjData = ((data) => {
  return async (dispatch) => {
    dispatch(setSelectedSourceBagData(data));
  };
});

export const setSelectedSourceBagId = ((tokenId) => {
  return async (dispatch) => {
    dispatch(updateSelectedSourceBagId(tokenId))
  };
});

const updateSelectedSourceBagId = (payload => {
  return {
    type: "UPDATE_SELECTED_SOURCE_BAG_ID",
    payload: payload
  };
});

export const setActiveTradeStep = ((step) => {
  return async (dispatch) => {
    dispatch(setActiveStep(step));
  };
});

export const updateTradePrice = ((price) => {
  return async (dispatch) => {
    dispatch(setTradePrice(price));
  };
});

export const executeTrade = ((tradeObj) => {
  return async (dispatch) => {
    await store
      .getState()
      .blockchain.smartContract.methods.tradeSlotPiece(
        parseInt(tradeObj.targetTokenId),
        parseInt(tradeObj.sourceTokenId),
        tradeObj.slotId
      )
      .send({
        from: tradeObj.account,
        value: tradeObj.price
      })
      .once("error", (err) => {
        console.log(err);
      });
  }
});

export const updateTradeTargetBag = (targetTokenId) => {
  return async (dispatch) => {
    dispatch(setSelectedTargetBag(targetTokenId));
  }
};

export const fetchSingleToken = (tokenId) => {
  return async (dispatch) => {
    dispatch(updateBlockchainSpinner(true));
    dispatch(fetchDataRequest());
    try {
      let selectedTokenData = await store
        .getState()
        .blockchain.smartContract.methods.tokenURI(tokenId)
        .call();

      dispatch(
        fetchSingleTokenSuccess({
          selectedTokenData
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load token information."));
    }
    dispatch(updateBlockchainSpinner(false));
  };
}

export const fetchSingleDisplayToken = (tokenId) => {
  return async (dispatch) => {
    dispatch(updateBlockchainSpinner(true));
    try {
      const getDisplayTokenFromTokenURI = await store
        .getState()
        .blockchain.smartContract.methods.tokenURI(tokenId)
        .call();

      dispatch(
        fetchSingleDisplayTokenSuccess({
          getDisplayTokenFromTokenURI
        })
      );
    } catch (err) {
      console.log(err);
    }
    dispatch(updateBlockchainSpinner(false));
  };
}

export const setItemsAndPrices = () => {
  return async (dispatch) => {
    const minPrice = 0.00001;
    const prices = store.getState().data.selectedTokenPrices;
    const initPrices = store.getState().data.selectedTokenPricesInitial;
    const blockchain = store.getState().blockchain;
    let itemArray = [];
    let priceArray = [];

    if (prices.weapon > minPrice && prices.weapon !== initPrices.weapon) {
      itemArray.push(0);
      priceArray.push(blockchain.web3.utils.toWei((prices.weapon.toString()), "ether"));
    }

    if (prices.chest > minPrice && prices.chest !== initPrices.chest) {
      itemArray.push(1);
      priceArray.push(blockchain.web3.utils.toWei((prices.chest.toString()), "ether"));
    }

    if (prices.helm > minPrice && prices.helm !== initPrices.helm) {
      itemArray.push(2);
      priceArray.push(blockchain.web3.utils.toWei((prices.helm.toString()), "ether"));
    }

    if (prices.belt > minPrice && prices.belt !== initPrices.belt) {
      itemArray.push(3);
      priceArray.push(blockchain.web3.utils.toWei((prices.belt.toString()), "ether"));
    }

    if (prices.boots > minPrice && prices.boots !== initPrices.boots) {
      itemArray.push(4);
      priceArray.push(blockchain.web3.utils.toWei((prices.boots.toString()), "ether"));
    }

    if (prices.gloves > minPrice && prices.gloves !== initPrices.gloves) {
      itemArray.push(5);
      priceArray.push(blockchain.web3.utils.toWei((prices.gloves.toString()), "ether"));
    }

    if (prices.neck > minPrice && prices.neck !== initPrices.neck) {
      itemArray.push(6);
      priceArray.push(blockchain.web3.utils.toWei((prices.neck.toString()), "ether"));
    }

    if (prices.ring > minPrice && prices.ring !== initPrices.ring) {
      itemArray.push(7);
      priceArray.push(blockchain.web3.utils.toWei((prices.ring.toString()), "ether"));
    }

    if (prices.shield > minPrice && prices.shield !== initPrices.shield) {
      itemArray.push(8);
      priceArray.push(blockchain.web3.utils.toWei((prices.shield.toString()), "ether"));
    }

    const itemsAndPrices = {
      itemArray: itemArray,
      priceArray: priceArray
    };

    dispatch(updateItemsAndPrices(itemsAndPrices));

    return itemsAndPrices
  }
}

export const fetchTokens = (tokenIds, showSpinner = true) => {
  return async (dispatch) => {
    try {
      if (showSpinner) {
        dispatch(updateBlockchainSpinner(true));
      }
      let ownerTokens = [];
      for (const tokenId of tokenIds) {
        const ownerToken = await store
          .getState()
          .blockchain.smartContract.methods.tokenURI(tokenId)
          .call();

        // each child in a list should have a unique key prop
        ownerTokens.push({
          token: getDisplayTokenFromTokenURI(ownerToken),
          key: tokenId
        });
      }

      dispatch(fetchAllOwnerTokensSuccess({
        ownerTokens
      }));
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load tokens."));
    }
    dispatch(updateBlockchainSpinner(false));
  }
}

export const checkIfWhitelisted = (address) => {
  return async (dispatch) => {
    try {
      const isWhitelisted = await store
        .getState()
        .blockchain.smartContract.methods.isWhitelisted(address)
        .call();

      dispatch(checkIfWhitelistedSuccess(isWhitelisted));
    } catch (err) {
      console.log(err);
    }
  }
}

export const fetchSlotPrices = (slotId, filterOutTokenIds) => {
  return async (dispatch) => {
    try {
      const slotPrices = await store
        .getState()
        .blockchain.smartContract.methods.getSlotPrices(slotId)
        .call();

      const filteredSlotPrices = slotPrices.filter(e => !filterOutTokenIds.includes(e[0]));
      dispatch(fetchSlotPricesSuccess(filteredSlotPrices));
      dispatch(updateBlockchainSpinner(false));
      return Promise.resolve(filteredSlotPrices);
    } catch (err) {
      console.log(err);
      dispatch(updateBlockchainSpinner(false));
    }
  }
}

export const fetchTokenPrices = (tokenId) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const blockchain = await store.getState().blockchain;

      const slotZeroDetail = await blockchain.smartContract.methods._bags(tokenId, 0)
        .call();
      const slotZero = slotZeroDetail.price ?? 0;

      const slotOneDetail = await blockchain.smartContract.methods._bags(tokenId, 1)
        .call();
      const slotOne = slotOneDetail.price ?? 0;

      const slotTwoDetail = await blockchain.smartContract.methods._bags(tokenId, 2)
        .call();
      const slotTwo = slotTwoDetail.price ?? 0;

      const slotThreeDetail = await blockchain.smartContract.methods._bags(tokenId, 3)
        .call();
      const slotThree = slotThreeDetail.price ?? 0;

      const slotFourDetail = await blockchain.smartContract.methods._bags(tokenId, 4)
        .call();
      const slotFour = slotFourDetail.price ?? 0;

      const slotFiveDetail = await blockchain.smartContract.methods._bags(tokenId, 5)
        .call();
      const slotFive = slotFiveDetail.price ?? 0;

      const slotSixDetail = await blockchain.smartContract.methods._bags(tokenId, 6)
        .call();
      const slotSix = slotSixDetail.price ?? 0;

      const slotSevenDetail = await blockchain.smartContract.methods._bags(tokenId, 7)
        .call();
      const slotSeven = slotSevenDetail.price ?? 0;

      const slotEightDetail = await blockchain.smartContract.methods._bags(tokenId, 8)
        .call();
      const slotEight = slotEightDetail.price ?? 0;

      const prices = {
        slotZero: blockchain.web3.utils.fromWei(slotZero.toString(), 'ether'),
        slotOne: blockchain.web3.utils.fromWei(slotOne.toString(), 'ether'),
        slotTwo: blockchain.web3.utils.fromWei(slotTwo.toString(), 'ether'),
        slotThree: blockchain.web3.utils.fromWei(slotThree.toString(), 'ether'),
        slotFour: blockchain.web3.utils.fromWei(slotFour.toString(), 'ether'),
        slotFive: blockchain.web3.utils.fromWei(slotFive.toString(), 'ether'),
        slotSix: blockchain.web3.utils.fromWei(slotSix.toString(), 'ether'),
        slotSeven: blockchain.web3.utils.fromWei(slotSeven.toString(), 'ether'),
        slotEight: blockchain.web3.utils.fromWei(slotEight.toString(), 'ether'),
      }

      dispatch(fetchTokenPricesSuccess(prices));

      return Promise.resolve(parseTokenPrices(prices));
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load token information."));
    }

  };
}

export const fetchOwnerTokenIds = (account, shouldFetchTokens = false, showSpinner = true) => {
  return async (dispatch) => {
    if (showSpinner) {
      dispatch(updateBlockchainSpinner(true));
    }

    dispatch(fetchDataRequest());
    let tokenIds = [];
    try {
      tokenIds = await store
        .getState()
        .blockchain.smartContract.methods.walletOfOwner(store.getState().blockchain.account)
        .call();

      let displayToken = {};
      if (tokenIds.length > 0) {
        displayToken = await store
          .getState()
          .blockchain.smartContract.methods.tokenURI(tokenIds[tokenIds.length - 1])
          .call();
      }

      dispatch(
        fetchOwnerTokenIdsSuccess({
          tokenIds,
          displayToken
        })
      );

      if (shouldFetchTokens === true) dispatch(fetchTokens(tokenIds, showSpinner));

    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }

    if (shouldFetchTokens === false) dispatch(updateBlockchainSpinner(false));

    return Promise.resolve(tokenIds);
  };
}

export const fetchData = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    dispatch(updateBlockchainSpinner(true));
    try {
      let name = await store
        .getState()
        .blockchain.smartContract.methods.name()
        .call();
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      let cost = await store
        .getState()
        .blockchain.smartContract.methods.cost()
        .call();
      let minSlotPrice = await store
        .getState()
        .blockchain.smartContract.methods.getMintCost()
        .call();
      let tokenIds = await store
        .getState()
        .blockchain.smartContract.methods.walletOfOwner(store.getState().blockchain.account)
        .call();

      let displayToken = {};
      if (tokenIds.length > 0) {
        displayToken = await store
          .getState()
          .blockchain.smartContract.methods.tokenURI(tokenIds[tokenIds.length - 1])
          .call();
      }

      dispatch(
        fetchDataSuccess({
          name,
          totalSupply,
          cost,
          minSlotPrice,
          tokenIds,
          displayToken,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
    dispatch(updateBlockchainSpinner(false));
  };
};

function getDisplayTokenFromTokenURI(payload) {
  const token = JSON.parse(decodeURIComponent(escape(window.atob(payload.split(',')[1]))));
  token.weapon = token.attributes[0].value;
  token.shield = token.attributes[8].value;
  token.chest = token.attributes[1].value;
  token.helm = token.attributes[2].value;
  token.belt = token.attributes[3].value;
  token.boots = token.attributes[4].value;
  token.gloves = token.attributes[5].value;
  token.neck = token.attributes[6].value;
  token.ring = token.attributes[7].value;
  return token;
}

function parseTokenPrices(payload) {

  return {
    "weapon": payload.slotZero,
    "shield": payload.slotEight,
    "chest": payload.slotOne,
    "helm": payload.slotTwo,
    "belt": payload.slotThree,
    "boots": payload.slotFour,
    "gloves": payload.slotFive,
    "neck": payload.slotSix,
    "ring": payload.slotSeven
  }
}